import React from 'react';
import { Helmet } from 'react-helmet';
import { getCurrentUrl } from 'utils/path';
import { getAbsoluteStaticUrl } from 'utils/path';

export function initOpenGraph(type, title, description, imageUrl, currentUrl) {
  const finalImageUrl = getImageUrl(imageUrl);
  return (
    <Helmet key='helmet-metadata'>
      <meta property='og:url' content={currentUrl || getCurrentUrl()} />
      <meta property='og:type' content={type} />
      <meta property='og:title' content={title || ''} />
      <meta property='og:description' content={description || ''} />
      <meta property='og:image' content={finalImageUrl} />
      <meta property='og:image:secure_url' content={finalImageUrl} />
    </Helmet>
  );
}

export function setOGTitle(title) {
  return (
    <Helmet>
      <meta property='og:title' content={title} />
    </Helmet>
  );
}

export function getImageUrl(imageUrl) {
  const defaultImageUrl = getAbsoluteStaticUrl(
    `/images/Optus_Sport_OG_Image_nov_2023.jpg`,
  );
  return imageUrl || defaultImageUrl;
}
