export function getCompetitionName(path) {
  switch (path) {
    case 'epl':
      return 'Premier League';
    case 'uefa-champions-league':
      return 'UEFA Champions League';
    case 'uefa-europa-league':
      return 'UEFA Europa League';
    case 'internationals':
      return 'Internationals';
    case 'womens':
      return "FA Women's Super League";
    case 'fifa-club-world-cup':
      return 'FIFA Club World Cup™';
    case 'j-league':
      return 'J.League';
    case 'uefa-euro-2020':
      return 'UEFA EURO 2020™';
    // case 'copa-america-2024':
    //   return 'Copa America USA 2024™';
    case 'dfb':
    case 'dfb-pokal':
      return 'DFB Pokal';
    case 'womens-world-cup-2023':
      return "FIFA Women's World Cup 2023™";
    case 'k-league':
      return 'K League';
    // case 'uefa-euro-2024':
    //   return 'UEFA EURO 2024';
    case 'nwsl':
      return "National Women's Soccer League";
    default:
      return null;
  }
}
